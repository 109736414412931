import React from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import Image from "../../components/image"
import SEO from "../../components/seo"
import { graphql } from "gatsby"

const IndexPage = ({ data }) => {
  // console.log("dataHome", data)
  const Homes = data.allSanityHome.nodes
  // console.log("homes", Homes)

  return (
    <Layout>
      <SEO title="Home" />
      {/* <div
        className={`relative bg-primary bg-no-repeat bg-cover overflow-hidden bg-${
          "url(" + Homes[0].image?.asset?.fluid?.src + ")"
        }`}
      > */}
      <div
        style={{
          position: "relative",
          backgroundColor: "#3490dc",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundImage: "url(" + Homes[0].image?.asset?.fluid?.src + ")",
          backgroundPosition: "center",
          width: "100%",
          height: "100vh",
        }}
      >
        <div className="relative pt-24">
          <main className="mt-16 mx-auto max-w-6xl px-4 sm:mt-24 sm:px-6 lg:mt-32">
            <div className="lg:grid lg:grid-cols-12 lg:gap-8">
              <div className="sm:text-center md:max-w-2xl md:mx-auto lg:col-span-6 lg:text-left">
                <h1 className="text-4xl home-border tracking-tight font-bold font-display text-white sm:text-5xl md:text-6xl ">
                  {Homes[0].header}
                </h1>
                <p className="mt-3 content-border font-bold font-display text-base text-white sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-3 md:text-2xl lg:mx-0">
                  {Homes[0].description}
                </p>
                <div className="mt-5 sm:mt-12 sm:flex sm:justify-center lg:justify-start">
                  <div className="rounded-md shadow">
                    {/* <Link
                      to="#"
                      className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-primary hover:bg-primary-light md:py-4 md:text-lg md:px-10"
                    >
                      Get started
                    </Link>
                  </div>
                  <div className="mt-3 sm:mt-0 sm:ml-3">
                    <Link
                      to="#"
                      className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-primary bg-gray-100 hover:bg-gray-200 md:py-4 md:text-lg md:px-10"
                    >
                      Explore the world
                    </Link> */}
                  </div>
                </div>
              </div>
              <div className="mt-12 relative sm:max-w-lg sm:mx-auto lg:mt-0 lg:max-w-none lg:mx-0 lg:col-span-6 lg:flex lg:items-center">
                <div className="relative w-full h-48 md:h-80 rounded-lg shadow-lg overflow-hidden">
                  <iframe
                    className="h-full w-full object-cover border-none"
                    src={Homes[0].url}
                    frameBorder="0"
                    allow="accelerometer; encrypted-media; gyroscope;"
                    allowFullScreen
                    title="Learn Legends of Auzu Video"
                  ></iframe>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query AllHomes {
    allSanityHome {
      nodes {
        header
        description
        url
        image {
          asset {
            fluid {
              src
            }
          }
        }
      }
    }
  }
`
